import logo from "./logo.png";
import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";

import { Unsubscribe } from "./Unsubscribe";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} alt="logo" />
          <Unsubscribe />
        </header>
      </div>
    </Router>
  );
}

export default App;
