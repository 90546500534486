import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API } from "aws-amplify";

import loader from "./loader.gif";

interface IExemptionStatus {
  email: string;
  denyMarketingEmails: boolean;
}

export function Unsubscribe() {
  const [exemptionStatus, setExemptionStatus] = useState<IExemptionStatus>();
  const [errorMessageHtml, setErrorMessageHtml] = useState("");
  const [flashText, setFlashText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const query = new URLSearchParams(useLocation().search);

  const code = query.get("c");

  useEffect(() => {
    const fetchExemptionStatus = async () => {
      setIsLoading(true);
      setErrorMessageHtml("");
      setFlashText("");

      const apiName = "EmailExemptionApi";
      const path = `/exemptions/${code}`;

      try {
        const record = await API.get(apiName, path, {});

        setExemptionStatus(record);
      } catch (ex) {
        setErrorMessageHtml(ex?.response?.data?.message || "An error occured");
      }

      setIsLoading(false);
    };

    fetchExemptionStatus();
  }, [code]);

  const denyMarketingEmails = async (denyMarketingEmails: boolean) => {
    setIsLoading(true);
    setErrorMessageHtml("");
    setFlashText("");

    const apiName = "EmailExemptionApi";
    const path = `/exemptions/${code}`;

    try {
      const record = await API.post(apiName, path, {
        body: { denyMarketingEmails },
      });

      setFlashText(
        `Your request to be ${
          denyMarketingEmails ? "unsubscribed" : "subscribed"
        } was processed successfully.`
      );

      setExemptionStatus(record);
    } catch (ex) {
      setErrorMessageHtml(ex?.response?.data?.message || "An error occured");
    }

    setIsLoading(false);
  };

  if (!code) {
    return null;
  }

  return (
    <section>
      {isLoading ? (
        <img src={loader} alt="spinner" />
      ) : errorMessageHtml ? (
        <div
          className="error"
          dangerouslySetInnerHTML={{ __html: errorMessageHtml }}
        />
      ) : (
        <>
          {flashText && <div className="flash">{flashText}</div>}

          {exemptionStatus?.denyMarketingEmails ? (
            <div className="Section-div">
              <strong>{exemptionStatus?.email}</strong> has been removed from
              our marketing list!
              <button onClick={() => denyMarketingEmails(false)}>
                Do you wish to resubscribe? Click here.
              </button>
            </div>
          ) : (
            <div className="Section-div">
              <strong>{exemptionStatus?.email}</strong> has been added to our
              marketing list!
              <button onClick={() => denyMarketingEmails(true)}>
                Do you wish to opt-out? Click here.
              </button>
            </div>
          )}
        </>
      )}
    </section>
  );
}
